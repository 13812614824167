<template>
  <div>
    <h1 style="text-align: center;">Server Side Rendering</h1>
    <Builder :user-mode="false" :builderData="builderData" v-if="builderData" @ready="downloadHQ" ref="builder"/>
  </div>
</template>
<script>
import { apiCall } from '@/client.js';
import Builder from '@/pages/Admin/Builder.vue';
export default {
    name: 'SSR',
    components: {
        Builder
    },
    data() {
        return {
            builderData: null
        }
    },

    methods: {
        downloadHQ(){
            this.$refs.builder.exportHQPng();
        },

        async getPageData(orderId, pageId, token){
            const result = await apiCall('GET', '/Order/Draft/' + orderId + '/Page/' + pageId, null, token);
            if(result.status == 200){
                this.builderData = {
                    layout: result.data,
                    product: result.data.order.product,
                    ssr: true
                }
            }
        }
    },

    mounted(){
        // get order id from query string
        let orderId = this.$route.query.orderId;
        // get page id from query string
        let pageId = this.$route.query.pageId;
        // get token from query string
        let ssrToken = this.$route.query.ssrToken;

        // if pageId and token are present
        if(orderId && pageId && ssrToken){
            // get page data
            this.getPageData(orderId, pageId, ssrToken);
        }
    }
}
</script>